<template>
  <div>
    <div class="grid crud-demo">
      <div class="col-12">
        <div class="card">
          <h6 class="">Administração de Pedidos</h6>
          <Toast/>
          <Toolbar class="mb-4">
            <template v-slot:start>
              <div class="my-2">

                <Button label="Novo Pedido" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />

                  <Calendar :showIcon="true"
                            :showButtonBar="true"
                            v-model="datasPeriodo"
                            :selectionMode="'range'"
                            @dateSelect='alterouPeriodo'
                            placeholder="Período de exibição"
                            dateFormat="dd/mm/yy">

                  </Calendar>


              </div>
            </template>

            <template v-slot:end>
              <FileUpload mode="basic" accept=".pdf" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" v-if="userData && userData.tipo === 'admin'"/>
              <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  v-if="userData && userData.tipo === 'admin'"/>


            </template>
          </Toolbar>

          <DataTable ref="dt" :value="pedidos" v-model:selection="selectedPedidos" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pedidos" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="pesqPedidos" placeholder="Enter para pesquisar..." style="width: 100%" @keydown="pesquisarPedido"/>
                  </span>
              </div>
            </template>

            <Column field="tipo" header="Tipo" :sortable="true" filterField="tipo" :showFilterMatchModes="false" >
              <template #body="slotProps">
                <span class="p-column-title">Tipo</span>
                {{slotProps.data.tipo}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo tipo"/>
              </template>
            </Column>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="id" header="ID" :sortable="true" filterField="id" :showFilterMatchModes="false" >
              <template #body="slotProps">
                <a @click="$router.push('pedidodetalhe/' + slotProps.data.id)" class="cursor-pointer">
                  {{slotProps.data.id}}
                </a>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo id"/>
              </template>
            </Column>

            <Column field="codexterno" header="Código" :sortable="true" filterField="codexterno" :showFilterMatchModes="false" >
              <template #body="slotProps">
                <span class="p-column-title">CodExterno</span>
                {{slotProps.data.codexterno}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>


            <Column field="cliente" header="Cliente" :sortable="true" filterField="cliente" >
              <template #body="slotProps">
                <span class="p-column-title">Cliente</span>
                {{slotProps.data.cliente}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código cliente"/>
              </template>
            </Column>

            <Column field="nome" header="Nome" :sortable="true" filterField="nome" >
              <template #body="slotProps">
                <span class="p-column-title">Nome</span>
                {{slotProps.data.nome}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo nome do cliente"/>
              </template>
            </Column>

            <Column field="cidade" header="Cidade" :sortable="true" class="text-left" filterField="cidade">
              <template #body="slotProps">
                <span class="p-column-title">Cidade</span>
                {{formatCurrency(slotProps.data.cidade)}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela cidade"/>
              </template>
            </Column>
            <Column field="estado" header="Estado" :sortable="true" class="text-right" filterField="estado">
              <template #body="slotProps">
                <span class="p-column-title">Estado</span>
                {{slotProps.data.estado}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela estado"/>
              </template>
            </Column>

            <Column field="status" header="Status" :sortable="true" filterField="status">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'pedido-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status"/>
              </template>
            </Column>


              <Column field="refer" header="Referência" :sortable="true" filterField="refer" :showFilterMatchModes="false" >
                  <template #body="slotProps">
                      <span class="p-column-title">Referência</span>
                      {{slotProps.data.refer}}
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela referência"/>
                  </template>
              </Column>

            <Column field="stscrg" header="Carga" :sortable="true" filterField="stscrg">
              <template #body="slotProps">
                <span class="p-column-title">Carga</span>
                <span :class="'cliente-badge status-' + (slotProps.data.stscrg ? slotProps.data.stscrg.toLowerCase() : '')">{{slotProps.data.stscrg}}</span>
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status carga"/>
              </template>
            </Column>

            <Column header="Data" dataType="date" style="min-width:10rem">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>

            <Column field="valortotal" header="Valor" :sortable="true" class="text-right" filterField="valortotal">
              <template #body="slotProps">
                <span class="p-column-title">Valor</span>
                {{formatCurrency(slotProps.data.vlritens)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>

            <Column header="Ações" class="text-right">
              <template #body="slotProps">

<!--                <Button icon="pi pi-eye" class="p-button-rounded p-button-info mr-2 mb-2" @click="$router.push('pedidopreview')" />-->
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2 mb-2" @click="$router.push('pedidodetalhe/' + slotProps.data.id)" />
                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2 mb-2" @click="visibleTimeline = true" />
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="pedidoDialog" :style="{width: '450px'}" header="Detalhes do Pedido" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/pedido/' + pedido.image" :alt="pedido.image" v-if="pedido.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="name">Cliente</label>
              <InputText id="name" v-model.trim="pedido.name" required="true" autofocus :class="{'p-invalid': submitted && !pedido.name}" />
              <small class="p-invalid" v-if="submitted && !pedido.name">Cliente é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="pedido.description" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="pedido.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'pedido-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'pedido-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Categoria</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="pedido.category" />
                  <label for="category1">Grande</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="pedido.category" />
                  <label for="category2">Médio</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="pedido.category" />
                  <label for="category3">Pequeno</label>
                </div>

              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="pedido.price" mode="currency" currency="BRL" locale="pt-BR" />
              </div>
              <div class="field col">
                <label for="quantity">Potência</label>
                <InputNumber id="quantity" v-model="pedido.quantity" integeronly />
              </div>
            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="savePedido" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deletePedidoDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="pedido">Confirma a exclusão de <b>{{pedido.name}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePedidoDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deletePedido" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deletePedidosDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="pedido">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePedidosDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPedidos" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Últimos Meses (Valor)</h6>
          <Chart type="bar" :data="comboData" :options="comboOptions" />

        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Valor Acumulado Estados (R$)</h6>
          <Chart type="polarArea" :data="polarData" :options="polarOptions" />
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
        <pedidos-list-timeline></pedidos-list-timeline>
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import PedidoService from '@/service/PedidoService';
import PedidosListTimeline from "@/components_esol/pedidos/PedidosListTimeline";


export default {
  components: {
    PedidosListTimeline

  },
  data() {
    return {
      pesqPedidos: '',
      datasPeriodo: null,
      userData: null,
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      pedidos: null,
      pedidoDialog: false,
      deletePedidoDialog: false,
      deletePedidosDialog: false,
      pedido: {},
      selectedPedidos: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Grande" },
        {name: "Médio" },
        {name: "Pequeno" },
      ],

      lineData: [],
      lineOptions: [],

      polarData: [],
      polarOptions: [],


      comboData: [],
      comboOptions: []

    }
  },
  pedidoService: null,
  created() {
    this.pedidoService = new PedidoService();
    this.initFilters();
  },
  mounted() {

      this.getPedidos()


  },
  methods: {
      pesquisarPedido(e){
          console.log('pesquisarPedido')
          console.log(e.keyCode)
          if (e.keyCode === 13){
              if (this.pesqPedidos.length < 3){
                  this.$toast.add({severity:'warn', summary: 'Texto Pesquisa', detail:'Favor informar no mínimo 3 caracteres para pesquisa', life: 3000});
              }else{
                  this.getPedidos()
              }
          }
      },

      getPedidos(){
          this.loading1 = true
          this.pedidoService.getPedidos({
              'q': this.pesqPedidos
          }).then((data) => {
              console.log('getPedidos mounted')
              // console.log(data[0])

              this.userData = data[0]
              this.pedidos = data[1]
              let labelsPolarChart = []

              this.loading1 = false

              this.pedidos.forEach((pedido) => {
                  pedido.valor = parseFloat(pedido.valor)
                  pedido.vlritens = parseFloat(pedido.vlritens)

                  pedido.date = new Date(pedido.created_at)
                  labelsPolarChart.push(pedido.estado)
              });
              labelsPolarChart = [...new Set(labelsPolarChart)]

              let valuesPolarData = []
              labelsPolarChart.forEach((label) => {
                  // console.log(label)
                  let orcsFiltr =  this.pedidos.filter(x => x.estado === label)
                  let valorTot = 0
                  orcsFiltr.forEach((orcto) => {
                      if (orcto.valor)
                          valorTot += orcto.valor
                      //console.log(orcto.valortotal)
                  })
                  valuesPolarData.push(valorTot)
                  // console.log('totalLabel')
                  // console.log(totalLabel)
              })

              // let totalLabelRJ =  this.pedidos.filter(x => x.estado === 'RJ').length
              // console.log('totalLabelRJ')
              // console.log(totalLabelRJ)

              let labelsLinesChart = data[2]
              let valuesLines1Chart = data[3]
              let valuesLines2Chart = data[4]


              this.comboData = {
                  labels: labelsLinesChart,
                  datasets: [{
                      type: 'line',
                      label: 'Tendência',
                      borderColor: '#42A5F5',
                      borderWidth: 2,
                      fill: false,
                      data: valuesLines1Chart
                  }, {
                      type: 'bar',
                      label: 'Faturado',
                      backgroundColor: '#66BB6A',
                      data: valuesLines1Chart,
                      borderColor: 'white',
                      borderWidth: 2
                  }, {
                      type: 'bar',
                      label: 'Não Faturado',
                      backgroundColor: '#FFA726',
                      data: valuesLines2Chart
                  }]
              }

              this.comboOptions = {
                  plugins: {
                      legend: {
                          labels: {
                              color: '#495057'
                          }
                      }
                  },
                  scales: {
                      x: {
                          ticks: {
                              color: '#495057'
                          },
                          grid: {
                              color: '#ebedef'
                          }
                      },
                      y: {
                          ticks: {
                              color: '#495057'
                          },
                          grid: {
                              color: '#ebedef'
                          }
                      }
                  }
              }

              this.polarData = {
                  datasets: [{
                      data: valuesPolarData,
                      backgroundColor: ["#42A5F5","#66BB6A","#FFA726","#26C6DA","#7E57C2"],
                      label: 'My dataset'
                  }],
                  labels: labelsPolarChart
              }

              this.polarOptions = {
                  plugins: {
                      legend: {
                          labels: {
                              color: '#495057'
                          }
                      }
                  },
                  scales: {
                      r: {
                          grid: {
                              color: '#ebedef'
                          }
                      }
                  }
              }


          })
      },

      alterouPeriodo(){
          console.log('alterouPeriodo')
          console.log(this.datasPeriodo)

          let dataIni = this.datasPeriodo[0]
          let dataFim = this.datasPeriodo[1]

          console.log(dataIni)
          console.log(dataFim)

          if (dataIni && dataFim){

              this.loading1 = true
              this.pedidos = []

              this.pedidoService.getPedidosPeriodo(this.datasPeriodo).then((data) => {
                  console.log('getPedidos mounted')
                  // console.log(data[0])
                  this.loading1 = false
                  this.userData = data[0]
                  this.pedidos = data[1]
                  let labelsPolarChart = []

                  this.pedidos.forEach((pedido) => {
                      pedido.valor = parseFloat(pedido.valor)
                      pedido.date = new Date(pedido.created_at)
                      labelsPolarChart.push(pedido.estado)
                  });
                  labelsPolarChart = [...new Set(labelsPolarChart)]

                  let valuesPolarData = []
                  labelsPolarChart.forEach((label) => {
                      // console.log(label)
                      let orcsFiltr =  this.pedidos.filter(x => x.estado === label)
                      let valorTot = 0
                      orcsFiltr.forEach((orcto) => {
                          if (orcto.valor)
                              valorTot += orcto.valor
                          //console.log(orcto.valortotal)
                      })
                      valuesPolarData.push(valorTot)
                      // console.log('totalLabel')
                      // console.log(totalLabel)
                  })

                  // let totalLabelRJ =  this.pedidos.filter(x => x.estado === 'RJ').length
                  // console.log('totalLabelRJ')
                  // console.log(totalLabelRJ)

                  let labelsLinesChart = data[2]
                  let valuesLines1Chart = data[3]
                  let valuesLines2Chart = data[4]


                  this.comboData = {
                      labels: labelsLinesChart,
                      datasets: [{
                          type: 'line',
                          label: 'Tendência',
                          borderColor: '#42A5F5',
                          borderWidth: 2,
                          fill: false,
                          data: valuesLines1Chart
                      }, {
                          type: 'bar',
                          label: 'Faturado',
                          backgroundColor: '#66BB6A',
                          data: valuesLines1Chart,
                          borderColor: 'white',
                          borderWidth: 2
                      }, {
                          type: 'bar',
                          label: 'Não Faturado',
                          backgroundColor: '#FFA726',
                          data: valuesLines2Chart
                      }]
                  }

                  this.comboOptions = {
                      plugins: {
                          legend: {
                              labels: {
                                  color: '#495057'
                              }
                          }
                      },
                      scales: {
                          x: {
                              ticks: {
                                  color: '#495057'
                              },
                              grid: {
                                  color: '#ebedef'
                              }
                          },
                          y: {
                              ticks: {
                                  color: '#495057'
                              },
                              grid: {
                                  color: '#ebedef'
                              }
                          }
                      }
                  }

                  this.polarData = {
                      datasets: [{
                          data: valuesPolarData,
                          backgroundColor: ["#42A5F5","#66BB6A","#FFA726","#26C6DA","#7E57C2"],
                          label: 'My dataset'
                      }],
                      labels: labelsPolarChart
                  }

                  this.polarOptions = {
                      plugins: {
                          legend: {
                              labels: {
                                  color: '#495057'
                              }
                          }
                      },
                      scales: {
                          r: {
                              grid: {
                                  color: '#ebedef'
                              }
                          }
                      }
                  }


              })





          }


      },
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 4, maximumFractionDigits: 4});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.$router.push({ path: '/pedidodetalhe/0' });
    },
    hideDialog() {
      this.pedidoDialog = false;
      this.submitted = false;
    },
    savePedido() {
      this.submitted = true;
      if (this.pedido.name.trim()) {
        if (this.pedido.id) {
          this.pedido.inventoryStatus = this.pedido.inventoryStatus.value ? this.pedido.inventoryStatus.value: this.pedido.inventoryStatus;
          this.pedidos[this.findIndexById(this.pedido.id)] = this.pedido;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Pedido Atualizado', life: 3000});
        }
        else {
          this.pedido.id = this.createId();
          this.pedido.code = this.createId();
          this.pedido.image = 'pedido-placeholder.svg';
          this.pedido.inventoryStatus = this.pedido.inventoryStatus ? this.pedido.inventoryStatus.value : 'ANÁLISE';
          this.pedidos.push(this.pedido);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Pedido Criado', life: 3000});
        }
        this.pedidoDialog = false;
        this.pedido = {};
      }
    },
    editPedido(pedido) {


      // this.pedido = {...pedido};
      // this.pedidoDialog = true;

      this.$router.push({ path: `/pedido/${pedido.id}` });


    },
    confirmDeletePedido(pedido) {
      this.pedido = pedido;
      this.deletePedidoDialog = true;
    },
    deletePedido() {
      this.pedidos = this.pedidos.filter(val => val.id !== this.pedido.id);
      this.deletePedidoDialog = false;
      this.pedido = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Pedido Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.pedidos.length; i++) {
        if (this.pedidos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deletePedidosDialog = true;
    },
    deleteSelectedPedidos() {
      this.pedidos = this.pedidos.filter(val => !this.selectedPedidos.includes(val));
      this.deletePedidosDialog = false;
      this.selectedPedidos = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Pedidos Deletados', life: 3000});
    },
    initFilters() {


      this.filters1 = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'codexterno': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'refer': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cliente': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'nome': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'cidade': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'estado': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'categ': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'valortotal': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'potencia': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},

      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
